'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
// routes
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSignupContext } from 'src/components/signup-popup/context/signup-context';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import { axiosPost } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';


export const COMPANY_TYPE_OPTIONS = [
  { value: 'advertiser', label: 'Advertiser' },
  { value: 'agency', label: 'Agency' },
  { value: 'other', label: 'Other' },
];

export const COMPANY_SIZE_OPTIONS = [
  { value: '1', label: '1 employee' },
  { value: '2-5', label: '2-5 employees' },
  { value: '6-15', label: '6-15 employees' },
  { value: '16-50', label: '16-50 employees' },
  { value: '51-100', label: '51-100 employees' },
  { value: '101-250', label: '101-250 employees' },
  { value: '250+', label: '250+ employees' },
];

// ----------------------------------------------------------------------

export default function ContactForm() {
  const signup = useSignupContext();
  const { enqueueSnackbar } = useSnackbar();

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    companyName: Yup.string().required(),
    companyType: Yup.string().required(),
    companySize: Yup.string().required(),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companyPosition: '',
    companyType: '',
    companySize: '',
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const url = `/web/contact`;
  const onSubmit = handleSubmit(async (data) => {
    try {
      const { success, message } = await axiosPost(url, data);
      if (success) {
        enqueueSnackbar(message, { variant: 'success', autoHideDuration: 10000 });
        signup.onClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(typeof error === 'string' ? error : error.message, { variant: 'error', autoHideDuration: 10000 });
    }
  });

  const textFieldInputSx = {
    background: (theme) => theme.palette.background.paper,
  };

  const selectInputSx = {
    height: '0!important',
    background: (theme) => theme.palette.background.paper,
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid xs={6} md={6}>
            <RHFTextField
              name='firstName'
              label='First name'
              required
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={6} md={6}>
            <RHFTextField
              name='lastName'
              label='Last name'
              required
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <RHFTextField
              name='email'
              label='Work email'
              required
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <RHFTextField
              name='phone'
              label='Phone'
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={6} md={6}>
            <RHFTextField
              name='companyName'
              label='Company name'
              required
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={6} md={6}>
            <RHFTextField
              name='companyPosition'
              label='Your title/position'
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={6} md={6}>
            <RHFSelect
              name='companyType'
              label='Company type'
              required
              sx={{
                '& .MuiInputBase-input': selectInputSx,
              }}
            >
              {COMPANY_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
          <Grid xs={6} md={6}>
            <RHFSelect
              name='companySize'
              label='Company size'
              required
              sx={{
                '& .MuiInputBase-input': selectInputSx,
              }}
            >
              {COMPANY_SIZE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
          <Grid xs={12} md={12}>
            <RHFTextField
              name='message'
              label='Anything you want us to know beforehand? Leave a note here!'
              multiline
              rows={3}
              InputProps={{ sx: textFieldInputSx }}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <Stack direction='row' spacing={1} justifyContent='center' alignItems='center' sx={{ mt: 1 }}>
              <LoadingButton
                size='large'
                type='submit'
                color='inherit'
                variant="contained"
                loading={isSubmitting}
                endIcon={<Iconify icon='eva:arrow-ios-forward-fill' width={18} sx={{ ml: -0.5 }} />}
                sx={{ pl: 4, pr: 3 }}
              >
                {'Let\'s talk!'}
              </LoadingButton>
              <LoadingButton
                size='small'
                sx={{ minWidth: 0 }}
                onClick={() => signup.onClose()}
              ><Iconify icon='uil:times' width={20} />
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Stack>
  );
}
