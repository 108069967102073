'use client';

import PropTypes from 'prop-types';
import { useMemo, useCallback, useState } from 'react';
// hooks
// utils
//
import { SignupContext } from './signup-context';

// ----------------------------------------------------------------------

const DEFAULT_MODE = 'register'

export function SignupProvider({ children }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupMode, setPopupMode] = useState(DEFAULT_MODE);

  // Drawer
  const onTogglePopup = useCallback((mode = DEFAULT_MODE) => {
    setPopupMode(mode || DEFAULT_MODE)
    setOpenPopup((prev) => !prev);
  }, []);

  const onClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      open: openPopup,
      mode: popupMode,
      onToggle: onTogglePopup,
      onClose: onClosePopup,
    }),
    [
      openPopup,
      popupMode,
      onClosePopup,
      onTogglePopup,
    ],
  );

  return <SignupContext.Provider value={memoizedValue}>{children}</SignupContext.Provider>;
}

SignupProvider.propTypes = {
  children: PropTypes.node,
};
